import React, { useContext, useEffect, useState } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import { initializeIcons } from '@uifabric/icons';
import { Avatar, makeStyles } from '@material-ui/core';
import { GlobalContext } from '../../storage/contexts/GlobalContext';
import { IPreUser } from '../../interfaces/IUser';
import FormControl from 'react-bootstrap/FormControl';
import { Simbolos, NavigationBar, SearchBar, ButtonEnterSearch, UserContainer, UserOptions, ExitButton, ArrowDown, Logo, HeaderSection, ButtonSearchSpace, SinoSpace} from './styles';
import '../../styles/compontents(DEPRECATED)/SuspendedMenu.css';
import SuspenseMenu,{AtalhosSuspenseMenu, UserSuspenseMenu} from '../SuspenseMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IFavoriteAdd} from '../../interfaces/components/IFavorite';
import { faBars, faSearch } from '@fortawesome/free-solid-svg-icons'
import { useHistory} from 'react-router-dom';
import CapemisaLogo from '../../imagens/CAPEMISA---Logotipo-negativo.png';
import CapemisaPortalLogo from '../../imagens/MeuPortalCapemisa_Negativo.png';
import {ClearStorage} from '../../functions/Logout';
import Notificacao from '../../components/Notificacao';
import IcoMoonIco from 'icomoon-react';
import { GetNotificacoes } from '../../API/usuario';
const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		'& > *': {
			margin: theme.spacing(1),
		},
	},
	small: {
		width: theme.spacing(3),
		height: theme.spacing(3),
	},
}));

const Header: React.FC<{}> = (): React.ReactElement => {
	const classes = useStyles();
	const { globalContext } = useContext(GlobalContext);
	const user = globalContext.PreUser as IPreUser;
	const CorrigeNome = (nameToFix:string):string =>
	{
		const nome=nameToFix;
		let hasSpace=false;
		let fixedName = '';
		for(let i=0;i<nome?.length;i++)
		{
			if(nome[i]===' ')
			hasSpace=true;
			if(!hasSpace)
			fixedName+=nome[i];
		}
		return fixedName.length<=10 ? fixedName : fixedName.slice(0,10)+"...";
	}
	let fixedName = CorrigeNome(user?.nome??"");
	

	return(
		<HeaderComponent
			usuario={user}
			isComplete={
				!(
					!(globalContext.PreUser?.endereco)
					|| !(globalContext.PreUser?.uf)
					|| !(globalContext.PreUser?.cep)
					|| !(globalContext.PreUser?.cidade)
					|| !(globalContext.PreUser?.bairro)
					|| !(globalContext.PreUser?.telefone1)
				)
			}
			// let informationUser = globalContext.PreUser ;
			allOptions={globalContext.AllFavorites as IFavoriteAdd[]}
			classes={classes}
			fixedName={fixedName}
		/>
	)
}

const HeaderComponent: React.FC<{ usuario?: IPreUser,allOptions:IFavoriteAdd[], classes: any, fixedName: string, isComplete?:boolean }> = (props): React.ReactElement => {
	const classes = props.classes;
	const fixedName = props.fixedName;
	const [showMenu,setShowMenu]= useState(false);
	const [showOptions,setShowOptions] = useState(false);
	const [showNotificacoes,setShowNotificacoes] = useState(false);
	const [busca,setBusca]= useState("");// eslint-disable-next-line
	const [notificacoes,setNotificacoes] = useState<{titulo:string,subTitulo:string,url:string}[]>([]);
	const [isInedito,setIsInedito] = useState<boolean>(true);
	const icones = useContext(GlobalContext).globalContext.Icones;
	useEffect(
		()=>{
			GetNotificacoes().then((response)=>{
				setNotificacoes(response.data);
			}).catch(()=>{
				setNotificacoes([{titulo:"Não foi possível recuperar suas notificações.",subTitulo:"",url:""}])
			})
		}
	,[])
	initializeIcons();
	let countervector: number[] = [];
	let history = useHistory();
	function stringEscape(s:string) {// eslint-disable-next-line
        return s ? s.replace(/\\|\n|\t|\v|'|\[|\]|"/g,'').replace(/[\x00-\x1F\x80-\x9F]/g,hex) : s;
        function hex(c:string) { var v = '0'+c.charCodeAt(0).toString(16); return '\\x'+v.substr(v.length-2); }
    }
	for (let count: number = 0; count < Math.ceil((props.allOptions.length / 9)); count++)
        countervector.push(count);
	return (
	<NavigationBar>
		<HeaderSection >
			{
				props.isComplete?
				<>
					<Simbolos id="megaMenuBar" onClick={()=>{setShowMenu(!showMenu)}} marginLeft="0" style={{ fontWeight: "bold",alignItems:"center",display:"flex",justifyContent:"center"}}>
						<FontAwesomeIcon icon={faBars} className="navBarItem" style={{ marginRight: "15px",marginBottom:"0", fontSize: "120%" }} />
					</Simbolos>
					<SuspenseMenu idDOMReference="megaMenuBar" handleClose={()=>{setShowMenu(false)}} show={showMenu} marginTop="45px" height="max-content;max-height:250px;padding-bottom:5px" marginLeft="0" width={(countervector.length*200)+"px"} component={<AtalhosSuspenseMenu allOptions={props.allOptions}/>}/>
				</>
				:
				<></>
			}
			<Simbolos marginLeft="0%" onClick={()=>{sessionStorage.clear();window.open("http://www.capemisa.com.br/")}}>
				<Logo src={CapemisaLogo}/>
			</Simbolos>
			<Simbolos onClick={()=>{if(props.isComplete)history.push("/inicio/")}} marginLeft="0%">
				<Logo src={CapemisaPortalLogo}/>
			</Simbolos>
		</HeaderSection>
		{
			props.isComplete?
			<SearchBar>
				<FormControl
						placeholder="O que você está procurando?"
						aria-label="Buscar"
						aria-describedby="Barra de Busca"
						style={{backgroundColor:"#FFFFFF",height:"26px",borderRight:"0px",  borderTopLeftRadius:"13px 13px", borderBottomLeftRadius:"13px 13px",
						}}
						value={busca} onChange={(e:any)=>{setBusca(stringEscape(e.target.value))}}
						/>
				<InputGroup.Append>
						<ButtonSearchSpace onClick={()=>{if(busca)history.push("/inicio/busca/"+busca)}}>
							<ButtonEnterSearch icon={faSearch}/>
						</ButtonSearchSpace>
				</InputGroup.Append>
			</SearchBar>
			:
			<></>
		}
		<HeaderSection width="auto">
			{
				props.isComplete?
				<>
					<UserContainer onClick={()=>{setShowOptions(!showOptions)}}>
						<div style={{display:"flex",alignItems:"center"}} translate="no">
						{/* <Avatar className={classes.small}></Avatar> */}
						<UserOptions translate="no">{fixedName}</UserOptions>
						{
							(!showOptions?<ArrowDown id="Arrow" iconName="ChevronDown"/>:<ArrowDown id="Arrow" iconName="ChevronUp"/>)
						}
						</div>
					</UserContainer>
					<div>
						<div>
							<SuspenseMenu idDOMReference={"Arrow"} handleClose={()=>{setShowOptions(false)}} show={showOptions} marginTop="45px" height="200px" width="208px" component={<UserSuspenseMenu/>}/>
						</div>
						<div style={{width:"60px"}}>
							<label onClick={ReduzirFonte} style={{color: "#FFFFFF",marginBottom:"0px", marginLeft: "10px", fontSize: "14px",cursor:"pointer" }} translate="no">A-</label>
							<label onClick={AumentarFonte} style={{marginLeft: "10px",marginBottom:"0px", fontSize: "16px", color: "#FFFFFF",cursor:"pointer"}} translate="no">A+</label>
						</div>
					</div>
					<SinoSpace id="SinoSpace" onClick={()=>{setIsInedito(false); setShowNotificacoes(true)}}>
						<IcoMoonIco iconSet={icones} size={"30px"} icon={isInedito?"CAPEMISA---Icones-33":"CAPEMISA---Icones-32"} color={"#FFFFFF"}/>
					</SinoSpace>
					<div>
						<SuspenseMenu handleClose={()=>{setShowNotificacoes(false)}} idDOMReference={"SinoSpace"} show={showNotificacoes} marginTop="45px" width="200px" height="auto" component={<Notificacao notificacoes={notificacoes}/>} />
					</div>
				</>
				:
				<></>
			}
			<ExitButton onClick={()=>{ClearStorage()}}>SAIR</ExitButton>
		</HeaderSection>
	</NavigationBar>);
}
let tamanhoFonte=14;
const AumentarFonte = ()=>
{
	if(tamanhoFonte<18)
	{
	tamanhoFonte+=1;
	document.body.style.fontSize=tamanhoFonte+"px";
	}
}
const ReduzirFonte = ()=>
{
	if(tamanhoFonte>10)
	{
	tamanhoFonte-=1;
	document.body.style.fontSize=tamanhoFonte+"px";
	}
}
export default Header;
