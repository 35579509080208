import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {MenuSpace, EspacoPraLista,Coluna, Atalho, AtalhoMessage, UserItem, UserTexto, UserIcoFabric, FullPage, UserIcoFA, UserIcoMoon } from './styles';
import { IFavoriteAdd } from '../../interfaces/components/IFavorite';
import { B2C_URL_SIGNIN } from '../../env_settings';
import {faSignOutAlt,faUser} from '@fortawesome/free-solid-svg-icons';
import { GlobalContext } from '../../storage/contexts/GlobalContext';
import IcomoonIteration from '../IcomoonIteration';
type IProps= {idDOMReference?:string,component:React.ReactElement,show:boolean,width:number|string,marginLeft?:number|string,height:number|string,marginTop:number|string,handleClose:any}
const SuspenseMenu: React.FC<IProps> = (props:IProps): React.ReactElement => {
    const Componente = props.component;
    const[marginLeft,setMarginLeft] = useState<string|number>(props.marginLeft??"0%");
    function GetWidth(){
        return Math.max(document.body.scrollWidth,
        document.documentElement.scrollWidth,
        document.body.offsetWidth,
        document.documentElement.offsetWidth,
        document.documentElement.clientWidth);
        }
    function FixMenuPosition()
    {
        const TotalPage:number = GetWidth() ;
            let fixedWidth:number=0;
            if(typeof(props.width)=="string")
            {
                if(props.width.search("%")===-1)
                {
                    if(props.width.search("px")===-1)
                    {
                        if(props.width.search("auto")===-1)
                        {
                            fixedWidth= parseInt(props.width);
                        }
                    }
                    else
                    {
                        fixedWidth= parseInt(props.width.replace(/p|x/g,""));
                    }
                }
                else
                {
                    fixedWidth= (TotalPage*parseInt(props.width.replace("%","")))/100;
                }
            }
            if(props.idDOMReference)
            {
                const XPositionFather = (document.getElementById(props.idDOMReference) ?? {offsetLeft: 0}).offsetLeft;
                if(TotalPage<XPositionFather+fixedWidth+30)
                {
                    setMarginLeft((XPositionFather-fixedWidth+30)+"px");
                }
                else
                {
                    setMarginLeft(XPositionFather+"px");
                }
            }
    }
    useEffect(
        ()=>{
            FixMenuPosition();
        }// eslint-disable-next-line
    ,[props.show])
    useEffect(()=>{
        window.addEventListener('resize', FixMenuPosition);
        // eslint-disable-next-line
    },[])
    return(
        <FullPage show={props.show} onClick={()=>{props.handleClose()}}>
            <MenuSpace marginTop={props.marginTop} marginLeft={marginLeft}width={props.width} height={props.height}>
                {Componente}
            </MenuSpace>
        </FullPage>);
}
const AtalhoPagina : React.FC<{atalhoAtual:IFavoriteAdd}> = (props): React.ReactElement => {
	function TempIco (url:string)
	{
		const palavraChave =".png";
		const palavraChave2 = "icones/ico";
		return url.slice(url.search(palavraChave2)+palavraChave2.length,url.search(palavraChave));
	}
    
    if(props.atalhoAtual.target !== undefined){
        return(
            <a href={props.atalhoAtual.url} style={{height:"10%",cursor:"pointer",marginTop:"1%"}} target={props.atalhoAtual.target}>
                <Atalho>
                    {(props.atalhoAtual.parent?"":<IcomoonIteration size="25px" icon={"CAPEMISA---Icones-"+TempIco(props.atalhoAtual.icoReference)} />)}
                    <AtalhoMessage color={(props.atalhoAtual.parent?"#636363":"black")} weight={(props.atalhoAtual.parent?"normal":"bold")}>{props.atalhoAtual.message}</AtalhoMessage>
                </Atalho>
            </a>
        )
    }

    return(
        <Link to={props.atalhoAtual.url} style={{height:"10%",cursor:"pointer",marginTop:"1%"}}>
            <Atalho>
                {(props.atalhoAtual.parent?"":<IcomoonIteration size="25px" icon={"CAPEMISA---Icones-"+TempIco(props.atalhoAtual.icoReference)} />)}
                <AtalhoMessage color={(props.atalhoAtual.parent?"#636363":"black")} weight={(props.atalhoAtual.parent?"normal":"bold")}>{props.atalhoAtual.message}</AtalhoMessage>
            </Atalho>
        </Link>
    )
}
type IItemUser = {item:{ico:{type:string,name:any},texto:string,url:string,notARoute?:boolean}}
export const UserItemRender:React.FC<IItemUser> = (props:IItemUser): React.ReactElement => {
    let history = useHistory();
    return(
        <UserItem onClick={()=>{if(props.item.notARoute){sessionStorage.clear();window.location.href=props.item.url}else{history.push(props.item.url)}}}>
            <RenderIcon item={props.item} />
            <UserTexto>{props.item.texto}</UserTexto>
        </UserItem>
    );
}
export const RenderIcon:React.FC<{item:{ico:{type:string,name:any}}}> = (props): React.ReactElement =>{
    const icones= useContext(GlobalContext).globalContext.Icones;
    const Component= ()=>{
        switch(props.item.ico.type)
        {
            case "FontAwesome":
                return(<UserIcoFA icon={props.item.ico.name}/>)
            case "IcoMoon":
                return(<UserIcoMoon size={15} iconSet={icones} icon={"CAPEMISA---Icones-"+props.item.ico.name} />)
            default:
                return(<UserIcoFabric iconName="StatusCircleQuestionMark"/>)
        }
    };
    return(
            <Component/>
    );
}
export const UserSuspenseMenu:React.FC<{}> = (props): React.ReactElement => {
    const opcoesMenu=[
    {
        "ico":{
            "name":faUser,
            "type":"FontAwesome",
        },
        "texto":"Meu Perfil",
        "url":"/inicio/perfil/"
    },
    {
        "ico":{
            "name":"47",
            "type":"IcoMoon",
        },
        "texto":"Acesse o site",
        "url":"http://www.capemisa.com.br/",
        "notARoute":true,//a
    },
    {
        "ico":{
            "name":faSignOutAlt,
            "type":"FontAwesome",
        },
        "texto":"Sair",
        "url":B2C_URL_SIGNIN,
        "notARoute":true,
    },
    ];
    return(
    <div style={{height:"90%",marginTop:"5%"}}>
        {
            opcoesMenu.map((item:any,i:number)=>{
                return(<UserItemRender key={i} item={item}/>);
            })
        }
    </div>);
};


type IPropsAtalhos= {allOptions:IFavoriteAdd[]};
export const AtalhosSuspenseMenu: React.FC<IPropsAtalhos> = (props:IPropsAtalhos): React.ReactElement => {
    const allItens=props.allOptions;
    let countervector: number[] = [];
	for (let count: number = 0; count < Math.ceil((allItens.length / 9)); count++)
        countervector.push(count);
    return(
        <EspacoPraLista>
            {
                countervector.map((numeral:number,d:number)=>{
                    return(
                    <Coluna width={`${Math.floor(100/countervector.length).toFixed(2)}%`} key={d+"col"}>
                        {allItens.map((item: IFavoriteAdd, i: number) => {
                            const show = ((((numeral + 1) * 9) - 9) <= i) && i < ((numeral + 1) * 9);
                            if (show) {
                                    return <AtalhoPagina key={item.message+i} atalhoAtual={item}/>
                            }
                            return ""
                        })}
                    </Coluna>);
                })
            }
        </EspacoPraLista>
    )
}
export default SuspenseMenu;